const StrapiParser = {
  parseResponseWithId(data) {
    if (Array.isArray(data))
      return data.map((item) => {
        return {id: item.id, ...item.attributes};
      });
    else return {id: data.id, ...data.attributes};
  },

  parseRecursiveWithId(obj) {
    // If the object is an array, recursively map its items.
    if (Array.isArray(obj)) {
      return obj.map((item) => this.parseRecursiveWithId(item));
    }

    // If the object is an object, recursively map its keys.
    if (typeof obj === "object" && obj !== null) {
      let newObj = {};

      // Preserve the 'id' if it exists
      if (Object.prototype.hasOwnProperty.call(obj, "id")) {
        newObj.id = obj.id;
      }

      // Check for 'data' key, and go inside it if found.
      if (Object.prototype.hasOwnProperty.call(obj, "data") && obj.data !== null) {
        const processedData = this.parseRecursiveWithId(obj.data);
        // If the data is an array, return it directly. Otherwise, merge with newObj.
        return Array.isArray(processedData) ? processedData : {...newObj, ...processedData};
      }

      // Check for 'attributes' key, and go inside it if found.
      if (Object.prototype.hasOwnProperty.call(obj, "attributes") && obj.attributes !== null) {
        return {...newObj, ...this.parseRecursiveWithId(obj.attributes)};
      }

      // Create a new object by mapping each key.
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key] = this.parseRecursiveWithId(obj[key]);
        }
      }
      return newObj;
    }

    // For primitive types, just return the value as-is.
    return obj;
  },

  parseResponseNoId(data) {
    return data.map((item) => {
      return item.attributes;
    });
  },
};

export default StrapiParser;

// end case, we reached the end of the object tree.
