<template>
  <div class="container d-flex justify-content-center align-items-center">
    <form @submit.prevent="onSubmit" class="login-form card">
      <span class="navbar-brand mb-3">
        <img class="logo" src="favicon.ico" alt="Quasar"/>
        Quasar CL Vault Manager
      </span>

      <div class="mb-3">
        <input
          v-model="form.identifier"
          placeholder="Email or username"
          type="text"
          class="form-control"
          id="inputEmail"
          aria-describedby="emailHelp"
          required
        />
      </div>

      <div class="mb-3">
        <input
          v-model="form.password"
          placeholder="Password"
          type="password"
          class="form-control"
          id="inputPassword"
          required
        />
      </div>

      <button type="submit" class="btn btn-quasar">Submit</button>
    </form>
  </div>
</template>

<script>
import User from "@/api/User";
import TokenStorage from "@/utils/TokenStorage";
import {mapMutations} from "vuex";
import mxToast from "@/mixin/toast";

export default {
  name: 'AuthView',

  mixins: [mxToast],

  data() {
    return {
      form: {
        identifier: "",
        password: ""
      }
    }
  },

  methods: {
    ...mapMutations(['setUserProfile']),

    async onSubmit() {
      this.isBusy = true;
      try {
        const connect = await User.postConnect(this.form);

        if (connect.jwt && connect.user) {
          TokenStorage.set(connect.jwt);
          this.setUserProfile(connect.user);
        } else {
          throw new Error("Invalid response from server");
        }
        this.toast.success("Logged in!")
      } catch (e) {
        this.toast.error("Login incorrect!")
        // TokenStorage.remove(); // TODO: maybe this is useless
        // this.toast.error(e); // TODO: Toast
      }
      this.isBusy = false;
    }
  }
}
</script>

<style scoped>
.logo {
  height: 64px;
}

.container {
  height: 100vh;
}

.login-form {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
</style>