import {createStore} from "vuex";
import User from "@/api/User";
import Vault from "@/api/Vault";
import Range from "@/api/Range";
import StrapiParser from "@/utils/StrapiParser";
import {osmosis} from "osmojs"
import {createWasmAminoConverters, wasmTypes} from "@cosmjs/cosmwasm-stargate";
import {Registry} from "@cosmjs/proto-signing";
import {
  AminoTypes,
  SigningStargateClient,
  createDefaultAminoConverters,
  defaultRegistryTypes
} from "@cosmjs/stargate";

export default createStore({
  /**
   * State containing primary Keys of the Vue store. Persisting of state objects.
   */
  state: {
    loading: {
      spotPrices: false,
    },
    user: {
      profile: null,
      signer: null,
      querier: null,
      walletAddress: null,
    },
    app: {
      vaults: null,
      selectedVault: null,
      ranges: null,
    },
    data: {
      spotPrices: {}
    }
  },

  getters: {
    // Loading

    getLoadingSpotPrices(state) {
      return state.loading.spotPrices;
    },

    // User

    getUserProfile(state) {
      return state.user.profile;
    },

    getUserSigner(state) {
      return state.user.signer
    },

    getUserQuerier(state) {
      return state.user.querier
    },

    getUserWalletAddress(state) {
      return state.user.walletAddress
    },

    // App

    getAppVaults(state) {
      return state.app.vaults;
    },

    getAppSelectedVault(state) {
      return state.app.selectedVault;
    },

    getAppRanges(state) {
      return state.app.ranges;
    },

    // Data

    getDataSpotPrices(state) {
      return state.data.spotPrices
    }
  },

  mutations: {
    // Loading

    setLoadingSpotPrices(state, value) {
      state.loading.spotPrices = value;
    },

    // User

    setUserProfile(state, value) {
      state.user.profile = value;
    },

    setUserSigner(state, value) {
      state.user.signer = value;
    },

    setUserQuerier(state, value) {
      state.user.querier = value;
    },

    setUserWalletAddress(state, value) {
      state.user.walletAddress = value;
    },

    // App

    setAppVaults(state, value) {
      state.app.vaults = value;
    },

    setAppSelectedVault(state, value) {
      state.app.selectedVault = value;
    },

    setAppRanges(state, value) {
      state.app.ranges = value;
    },

    // Data

    setDataSpotPrices(state, value) {
      state.data.spotPrices = value
    }
  },

  actions: {
    // User
    async fetchUserProfile({commit}) {
      commit("setUserProfile", await User.getUser());
    },

    async fetchUserWallet({commit}) {
      if (!window.keplr) {
        alert("Please install keplr extension");
      } else {
        const chainId = process.env.VUE_APP_OSMOSIS_CHAIN_ID;

        // Enabling before using the Keplr is recommended.
        // This method will ask the user whether to allow access if they haven't visited this website.
        // Also, it will request that the user unlock the wallet if the wallet is locked.
        await window.keplr.enable(chainId);
        window.keplr.defaultOptions = {
          sign: {preferNoSetFee: true, preferNoSetMemo: true, disableBalanceCheck: true},
        };

        const offlineSigner = window.getOfflineSignerOnlyAmino(chainId)

        // Initialize the gaia api with the offline signer that is injected by Keplr extension.
        const client = await osmosis.ClientFactory.createRPCQueryClient({
          rpcEndpoint: process.env.VUE_APP_OSMOSIS_RPC,
        });
        commit("setUserQuerier", client);

        const signer = await SigningStargateClient.offline(offlineSigner, {
          registry: new Registry([...defaultRegistryTypes, ...wasmTypes]),
          aminoTypes: new AminoTypes({
            ...createDefaultAminoConverters(),
            ...createWasmAminoConverters(),
          }),
        });
        commit("setUserSigner", signer);

        const accounts = await offlineSigner.getAccounts();
        commit("setUserWalletAddress", accounts[0].address);
      }
    },

    // App

    async fetchAppVaults({commit}) {
      const {data} = await Vault.getVaults();
      commit("setAppVaults", StrapiParser.parseRecursiveWithId(data));
    },

    async fetchAppRanges({ commit }, payload) {
      // Provide default values right in the destructuring assignment
      const { selectedVault = null, page = 1 } = payload || {};
      const { data, meta } = await Range.getRanges(selectedVault, page);
      commit("setAppRanges", { data: StrapiParser.parseRecursiveWithId(data), meta });
    },

    // Data

    async fetchDataSpotPrices({commit}, vaults) {
      if (!vaults || !vaults.length) throw new Error("No vaults loaded")

      // TODO: Redundant here, remove
      const client = await osmosis.ClientFactory.createRPCQueryClient({
        rpcEndpoint: process.env.VUE_APP_OSMOSIS_RPC,
      });

      let spotPrices = {}
      commit("setLoadingSpotPrices", true);
      for (const vault of vaults) {
        if (!spotPrices[vault.poolId]) {
          try {
            const pool = (await client.osmosis.poolmanager.v1beta1.pool({poolId: vault.poolId})).pool;
            const res = await client.osmosis.poolmanager.v1beta1.spotPrice({
              poolId: vault.poolId,
              baseAssetDenom: pool.token0,
              quoteAssetDenom: pool.token1,
            });
            spotPrices[vault.poolId] = res.spotPrice
          } catch (e) {
            spotPrices[vault.poolId] = spotPrices[vault.poolId] || '...'
          }
        }
      }

      commit("setDataSpotPrices", spotPrices);
      commit("setLoadingSpotPrices", false);
    }
  },

  modules: {},
});
