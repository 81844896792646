<template>
  <div class="text-white text-center">
    <div class="spinner-border" role="status"></div> Loading...
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
}
</script>