import {unixTime} from './Date'
import TokenParser from "./TokenParser";

/**
 * TokenStorage class
 * @type {{set(*, *): void, get(): Promise<string|*>, remove(): void}}
 */
const TokenStorage = {
  /**
   * Get the stored JWT token if stored. Refresh it if expired. This is used inside the Ajax.js library to dynamically refresh the token before API calls.
   * @returns {Promise<string|*>}
   */
  async get() {
    const token = localStorage.getItem('token')

    if (token) {
      let expiry = localStorage.getItem('token-expiry')
      expiry = expiry ? parseInt(expiry, 10) : 0

      // if expired
      if (expiry < unixTime()) {
        TokenStorage.remove() // removing old token
        // TODO: Toast warning
      }
    }
    return token // it could be null but it's fine
  },

  /**
   * Set JWT token and refreshToken for further use and refresh to localStorage
   * @param token
   */
  set(token) {
    const parsed = new TokenParser(token)
    localStorage.setItem('token', token)
    localStorage.setItem('token-expiry', parsed.getClaim('exp'))
  },

  /**
   * Remove the JWT token and refreshToken from localStorage
   */
  remove() {
    localStorage.removeItem('token')
    localStorage.removeItem('token-expiry')
  }
}

export default TokenStorage
