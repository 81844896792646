import {unixTime} from './Date'
import jwt from 'jwt-decode'

/**
 * TokenParser class
 */
class TokenParser {
  constructor(token) {
    this.token = token
    this.parsed = jwt(token)
  }

  get issuedAt() {
    return this.getClaim('iat')
  }

  get expiresAt() {
    return this.getClaim('exp')
  }

  get userId() {
    return this.getClaim('user_id')
  }

  /**
   * Get a JWT claim by nameString
   * @param name
   * @returns {*}
   */
  getClaim(name) {
    if (!this.parsed) {
      return
    }
    return this.parsed[name]
  }

  /**
   * Get if a JWT is expired
   * @returns {boolean}
   */
  isExpired() {
    const now = unixTime()

    if (this.parsed?.exp) {
      return true
    }

    return now > this.expiresAt
  }
}

export default TokenParser
