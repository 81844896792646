import Ajax from './Ajax'
import qs from "qs";

const Range = {
  getRanges: (selectedVault, page) => {
    let queryObject = {
      sort: {createdAt: "desc"},
      populate: {
        vault: "*",
        executions: {
          fields: ["hash", "type", "status", "height", "rawLog"],
          populate: {
            signatures: {
              populate: {
                user: {
                  // TODO: This needs Roles.Auth.User.find() to be enabled,
                  //  which is a minor privacy issue considering only auth users, we manually created,
                  //  can ask information about all the registered users.
                  fields: ["address"]
                }
              }
            }
          }
        }
      },
      pagination: {
        page
      },
      filters: {},
    }

    if (selectedVault) queryObject.filters.vault = {contractAddress: {$eq: selectedVault}}

    const query = qs.stringify(queryObject)

    return Ajax.get(`/ranges?${query}`, {})
  },

  postRanges: (params) => {
    return Ajax.post('/ranges', {data: params})
  },

  deleteRanges: (rangeId, params) => {
    return Ajax.delete(`/ranges/${rangeId}`, params)
  }
}

export default Range
