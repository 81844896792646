import Ajax from './Ajax'
import qs from "qs";

const Vault = {
  getVaults: (params) => {
    const query = qs.stringify({
      sort: {name: "asc"},
      pagination: {
        limit: 100
      }
    })

    return Ajax.get(`/vaults?${query}`, params)
  },
}

export default Vault
