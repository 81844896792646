import axios from "axios";
import TokenStorage from "../utils/TokenStorage";

const baseUrl = process.env.VUE_APP_API_BASE_URI;

const createConfig = (endpoint, method, params, extraConfig, hasData = false) => {
  const config = {
    ...extraConfig,
    url: endpoint,
    method: method,
    baseURL: baseUrl,
  };

  if (hasData) config.data = params;
  else config.params = params;

  return config;
};

const ajaxRequest = async (config) => {
  try {
    const token = await TokenStorage.get();
    config.headers = {} // init prop

    if (token) config.headers['Authorization'] = `Bearer ${token}`;

    const response = await axios.request(config);

    return config.responseType === "blob" ? response : response.data;
  } catch (e) {
    if (e.response && e.response.status === 401)
      TokenStorage.remove();

    throw new Error(e.response ? e.response.data.error.message : 'An unexpected error occurred');
  }
};

const Ajax = {
  get: (endpoint, params, config = {}) => ajaxRequest(createConfig(endpoint, "GET", params, config)),

  post: (endpoint, params, config = {}) => ajaxRequest(createConfig(endpoint, "POST", params, config, true)),

  put: (endpoint, params, config = {}) => ajaxRequest(createConfig(endpoint, "PUT", params, config, true)),

  delete: (endpoint, params, config = {}) => ajaxRequest(createConfig(endpoint, "DELETE", params, config, !!params)),
};

export default Ajax;
