<template>
  <div id="app" class="d-flex flex-column">
    <div class="main-section flex-grow-1" v-if="!isBusy">
      <router-view v-if="getUserProfile"></router-view>
      <AuthView v-else/>
    </div>
    <LoadingComponent v-else />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TokenStorage from "@/utils/TokenStorage";
import mxToast from "./mixin/toast";
import AuthView from "@/views/Auth.vue";
import LoadingComponent from "@/components/Layout/LoadingComponent.vue";

export default {
  name: "App",
  components: {LoadingComponent, AuthView},

  mixins: [mxToast],

  computed: {
    ...mapGetters(["getUserProfile"]),
  },

  data() {
    return {
      isBusy: true,
    };
  },

  async created() {
    try {
      // Fetch the User in order to fill this.getUserProfile on Vuex store
      if (await TokenStorage.get()) {
        await this.fetchUserProfile()
        // Only for VERIFIER user role
        if (this.getUserProfile.type === 'VERIFIER') await this.fetchUserWallet()
      }
    } catch (e) {
      this.toast.error(e.toString());
    }
    this.isBusy = false;
  },

  methods: {
    ...mapActions(["fetchUserProfile", "fetchUserWallet"])
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/theme.scss";
</style>
