import Ajax from './Ajax'

const User = {
  getUser: (params) => {
    return Ajax.get('/users/me', params)
  },

  postConnect: (params) => {
    return Ajax.post('/auth/local', params)
  },
}

export default User
